import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import IndexStyles from "../components/index.module.css"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <p>Zach Ambrose, software developer in Brooklyn, NY.</p>
    <p>
      Always happy to{" "}
      <a href="mailto:zachambrose+contact@gmail.com">grab a &#9749;</a>.
      <br />
    </p>
    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div> */}
    {/* <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default IndexPage
